import React, { useState, useEffect, useRef } from 'react';
import { Image, Platform, View, StyleSheet, Text, TouchableOpacity, TextInput, Modal, FlatList} from 'react-native';
import schoolLocationData from './assets/school_location.geojson';
import freeWiFi from './assets/free_wifi.geojson'
import washroom_pfr from '/assets/washroom_pfr.geojson'
import speedTrap from '/assets/speedtrap_location.geojson'
import drinking_water from '/assets/drinking_water.geojson'
import red_light_camera from '/assets/red_light_camera.geojson'
import bench from '/assets/bench.geojson'
import litter from '/assets/litter_bin.geojson'
import street_parking from '/assets/street_parking.geojson'
import bicycle_parking from '/assets/bicycle_parking.geojson'
import police_boundaries from '/assets/police_boundaries.geojson'



import { Asset } from 'expo-asset';



const GOOGLE_MAPS_API_KEY = 'AIzaSyAfsjsI3wgmes1v_k8rOwKwedfwWtCvx1k'; // Replace with your Google Maps API Key

const App = () => {
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [mapInstance, setMapInstance] = useState(null);
  const [isWindowVisible, setIsWindowVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showChatBubble, setShowChatBubble] = useState(false);
  const [chatBubbleContent, setChatBubbleContent] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isSuggestionVisible, setIsSuggestionVisible] = useState(false);




  const suggestions = ['School', 'WiFi', 'Washroom', 'Speed Trap', 'Water Fountain', 'Water', 'Red Light Camera', 'Bench', 'Trashcan', 'Street Parking', 'Bicycle Parking', 'Police Boundaries'];
  const markerImage = Asset.fromModule(require('./assets/marker.png')).uri;
  const schoolmarkerImage = Asset.fromModule(require('./assets/noun_school.png')).uri;


  const onSuggestionSelect = (suggestion) => {
    setSearchTerm(suggestion);
    triggerSearch(suggestion); // Function to handle the search logic
    closeSuggestionBox(); // Close the suggestion box after selection
    setFilteredSuggestions([]); // Close the suggestion list
  };

  const handleSearchButtonPress = () => {
    if (searchTerm) {
      triggerSearch(searchTerm);
      clearMarkers(); // Clear existing markers before adding new ones
    }
  };
  

  const handlePickerSelect = (suggestion) => {
    setSearchTerm(suggestion);
    setShowPicker(false);
    triggerSearch(suggestion); // Function to handle the search logic
    setFilteredSuggestions([]); // Close the suggestion list
  };

  const closeSuggestionBox = () => {
    setFilteredSuggestions([]);
  };



  useEffect(() => {
    if (Platform.OS === 'web') {
      loadGoogleMapsApi();
    }
  }, []);

  const loadGoogleMapsApi = () => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initMap`;
    script.async = true;
    script.defer = true;
    window.initMap = initMap;
    document.body.appendChild(script);

    script.onerror = () => {
      console.error('Google Maps API failed to load');
    };
  };
  

  const initMap = () => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 43.7232, lng: -79.3832 },
      zoom: 15,
    });

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(userLocation);


          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
          
          // Optionally add a marker at the user's location
          new window.google.maps.Marker({
            position: userLocation,
            map: map,
            title: 'Your Location',
          });
          
        },
        (error) => {
          console.error('Error getting location', error);
          // Handle error or use default location
        },
        { enableHighAccuracy: true } // Options for better accuracy
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      // Handle browsers that don't support Geolocation
    }



    setMapInstance(map); // Update the map instance in the state
  };

  const handleSearchChange = (text) => {
    setSearchTerm(text);

    if (text) {
      // Filter suggestions based on the input
      const matchedSuggestions = suggestions.filter(suggestion =>
        suggestion.toLowerCase().startsWith(text.toLowerCase())
      );
      setFilteredSuggestions(matchedSuggestions);
    } else {
      // If no input, clear the suggestions
      setFilteredSuggestions([]);
    }
  };

  const handleKeyPress = (e) => {
    setFilteredSuggestions([]);


    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'school' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(schoolLocationData)
        .then(response => response.json())
        .then(data => addGeoJsonDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'wifi' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(freeWiFi)
        .then(response => response.json())
        .then(data => addWiFiDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'washroom' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(washroom_pfr)
        .then(response => response.json())
        .then(data => addWashroomPFRDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'speed trap' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(speedTrap)
        .then(response => response.json())
        .then(data => addSpeedTrapDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if ((e.key === 'Enter' && searchTerm.toLowerCase() === 'water fountain' && mapInstance) || (e.key === 'Enter' && searchTerm.toLowerCase() === 'water' && mapInstance)) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(drinking_water)
        .then(response => response.json())
        .then(data => addDrinkingWaterDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'red light camera' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(red_light_camera)
        .then(response => response.json())
        .then(data => addRedLightCameraDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'bench' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(bench)
        .then(response => response.json())
        .then(data => addBenchDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'trashcan' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(litter)
        .then(response => response.json())
        .then(data => addLitterDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'street parking' && mapInstance) {
      clearMarkers();
  
      fetch(street_parking)
        .then(response => response.json())
        .then(data => addStreetParkingDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent('Street Parking');
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'bicycle parking' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(bicycle_parking)
        .then(response => response.json())
        .then(data => addBicycleParkingDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (e.key === 'Enter' && searchTerm.toLowerCase() === 'police boundaries' && mapInstance) {
      clearMarkers();
  
      fetch(police_boundaries)
        .then(response => response.json())
        .then(data => addPoliceBoundariesDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent('Police Boundaries');
    }


  };

  const handleSearchKeyPress = (e) => {
    setFilteredSuggestions([]);


    if (searchTerm.toLowerCase() === 'school' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(schoolLocationData)
        .then(response => response.json())
        .then(data => addGeoJsonDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'wifi' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(freeWiFi)
        .then(response => response.json())
        .then(data => addWiFiDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'washroom' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(washroom_pfr)
        .then(response => response.json())
        .then(data => addWashroomPFRDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'speed trap' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(speedTrap)
        .then(response => response.json())
        .then(data => addSpeedTrapDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if ((searchTerm.toLowerCase() === 'water fountain' && mapInstance) || (searchTerm.toLowerCase() === 'water' && mapInstance)) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(drinking_water)
        .then(response => response.json())
        .then(data => addDrinkingWaterDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'red light camera' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(red_light_camera)
        .then(response => response.json())
        .then(data => addRedLightCameraDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'bench' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(bench)
        .then(response => response.json())
        .then(data => addBenchDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'trashcan' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(litter)
        .then(response => response.json())
        .then(data => addLitterDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'street parking' && mapInstance) {
      clearMarkers();
  
      fetch(street_parking)
        .then(response => response.json())
        .then(data => addStreetParkingDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent('Street Parking');
    }

    if (searchTerm.toLowerCase() === 'bicycle parking' && mapInstance) {

      clearMarkers();

      // Fetch and display data only when "Enter" is pressed and searchTerm is "school"
      fetch(bicycle_parking)
        .then(response => response.json())
        .then(data => addBicycleParkingDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent(searchTerm);
    }

    if (searchTerm.toLowerCase() === 'police boundaries' && mapInstance) {
      clearMarkers();
  
      fetch(police_boundaries)
        .then(response => response.json())
        .then(data => addPoliceBoundariesDataToMap(mapInstance, data))
        .catch(error => console.error('Failed to load GeoJSON data:', error));
      setShowChatBubble(true);
      setChatBubbleContent('Police Boundaries');
    }


  };

  const clearChatBubble = () => {
    setShowChatBubble(false);
    setChatBubbleContent('');
  };
  
  
  const addGeoJsonDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);


          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
          

  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.NAME,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.NAME}</h3>
              <p>Type: ${properties.SCHOOL_TYPE_DESC}</p>
              <p>Address: ${properties.ADDRESS_FULL}</p>
              <p>City: ${properties.CITY}</p>
              <p>Postal Code: ${properties.POSTAL_CODE}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };

  const addWiFiDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);

          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.location_name,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.public_name}</h3>
              <p>Address: ${properties.address}</p>
              <p>Owner: ${properties.owner}</p>
              <p>Postal Code: ${properties.postal_code}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };

  const addWashroomPFRDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);

          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.location,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.location}</h3>
              <p>Type: ${properties.type}</p>
              <p>Details: ${properties.location_details}</p>
              <p>Address: ${properties.address}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };


  const addSpeedTrapDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);

          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.location,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.location}</h3>
              <p>Status: ${properties.Status}</p>
              <p>Ward: ${properties.ward}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };

  const addDrinkingWaterDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);

          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.location,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.location}</h3>
              <p>Type: ${properties.type}</p>
              <p>Details: ${properties.location_details}</p>
              <p>Address: ${properties.address}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };

  const addRedLightCameraDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);

          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.NAME,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.NAME}</h3>
              <p>Details: ${properties.LINEAR_NAME_FULL_1}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };


  const addBenchDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);

          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.ADDRESSSTREET,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.ADDRESSSTREET}</h3>
              <p>Status: ${properties.STATUS}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };

  const addLitterDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);

          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.ADDRESSSTREET,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.ADDRESSSTREET}</h3>
              <p>Status: ${properties.STATUS}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };

  const addStreetParkingDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
  
      if (geometry && geometry.type === 'MultiPolygon') {
        geometry.coordinates.forEach(polygon => {
          polygon.forEach(ring => {
            const polyPath = ring.map(coord => ({ lat: coord[1], lng: coord[0] }));
            const mapPolygon = new google.maps.Polygon({
              paths: polyPath,
              strokeColor: '#808000', // Olive light green stroke color
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#90EE90', // Light olive green fill color
              fillOpacity: 0.35,
            });
  
            mapPolygon.setMap(mapInstance);
  
            // Optional: Add an info window or other interactive elements here
          });
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });
  };

  const addBicycleParkingDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    let newMarkers = [];

    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
      if (geometry && geometry.type === 'MultiPoint') {
        // Handle MultiPoint. Assuming you want to create a marker for each point in MultiPoint
        geometry.coordinates.forEach(coord => {
          const [longitude, latitude] = coord;
          const position = new google.maps.LatLng(latitude, longitude);


          const userLocationIcon = {
            url: markerImage,
            scaledSize: new google.maps.Size(35, 40), // Size of the icon
            origin: new google.maps.Point(0, 0), // Origin point of the icon
            anchor: new google.maps.Point(15, 30) // Anchor at the bottom center of the icon
          };
          

  
          const marker = new google.maps.Marker({
            position,
            map: mapInstance,
            title: properties.NAME,
            icon: userLocationIcon,
          });
  
          // InfoWindow setup
          const infoWindowContent = `
            <div>
              <h3>${properties.LINEAR_NAME_FULL}</h3>
              <p>Type: ${properties.PARKING_TYPE}</p>
              <p>Address: ${properties.ADDRESS_FULL}</p>
              <a href="https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}" target="_blank">Find Directions on Google Maps</a>
            </div>
          `;
  
          const infoWindow = new google.maps.InfoWindow({
            content: infoWindowContent
          });
  
          marker.addListener('click', () => {
            infoWindow.open(mapInstance, marker);
          });

          newMarkers.push(marker); 
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });

    setMarkers(newMarkers);
  };

  const addPoliceBoundariesDataToMap = (mapInstance, data) => {
    if (!data || !Array.isArray(data.features)) {
      console.warn('GeoJSON data is undefined or features are not in an array. Skipping...');
      return;
    }
  
    data.features.forEach((feature) => {
      const { geometry, properties } = feature;
  
      if (geometry && geometry.type === 'MultiPolygon') {
        geometry.coordinates.forEach(polygon => {
          const polyPath = polygon[0].map(coord => ({ lat: coord[1], lng: coord[0] }));
          
          const mapPolygon = new google.maps.Polygon({
            paths: polyPath,
            strokeColor: '#808000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#90EE90',
            fillOpacity: 0.35,
          });
          mapPolygon.setMap(mapInstance);
  
          const infoWindow = new google.maps.InfoWindow();
          google.maps.event.addListener(mapPolygon, 'click', (event) => {
            const contentString = `
              <div>
                <h3>${properties.UNIT_NAME}</h3>
                <p>Address: ${properties.ADDRESS}</p>
                <p>Division: ${properties.DIV}</p>
                <p>City: ${properties.CITY}</p>
                <p>Area: ${properties.AREA_SQKM.toFixed(2)} sq km</p>
              </div>
            `;
            infoWindow.setContent(contentString);
            infoWindow.setPosition(event.latLng); // Set position at the click location
            infoWindow.open(mapInstance);
          });
        });
      }
      // Handle other geometry types if your GeoJSON contains them
    });
  };
  
  const clearMarkers = () => {
    markers.forEach(marker => marker.setMap(null)); // Remove each marker from the map
    setMarkers([]); // Clear
    setShowChatBubble(false); // Hide the chat bubble
  };
  

  return (
    <View style={styles.container}>
      <TouchableOpacity 
        style={styles.circleButton} 
        onPress={() => setIsWindowVisible(!isWindowVisible)}
      >
        <Image 
          source={require('./assets/buttonlogo.png')} 
          style={styles.buttonImage} 
        />
      </TouchableOpacity>
  
      {isWindowVisible && (
        <View style={styles.popupWindow}>
          <View style={styles.searchContainer}>
            <TextInput
              style={styles.searchInput}
              placeholder="Search..."
              onChangeText={handleSearchChange}
              value={searchTerm}
              onKeyPress={handleKeyPress}
            />
            <TouchableOpacity
              style={styles.dropdownButton}
              onPress={() => setIsDropdownVisible(!isDropdownVisible)}
            >
              <Text style={styles.dropdownButtonText}>▼</Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={styles.searchButton}
            onPress={handleSearchKeyPress}
          >
            <Text style={styles.searchButtonText}>Search</Text>
          </TouchableOpacity>
    
          {/* Conditionally Render Suggestion List */}
          {searchTerm && (
            <View style={styles.suggestionContainer}>
              {filteredSuggestions.map((suggestion, index) => (
                <TouchableOpacity
                  key={index}
                  style={styles.suggestionItem}
                  onPress={() => {
                    onSuggestionSelect(suggestion);
                    setFilteredSuggestions([]); // This line will close the suggestion box
                  }}
                >
                  <Text>{suggestion}</Text>
                </TouchableOpacity>
              ))}
              <TouchableOpacity
                style={styles.closeSuggestionButton}
                onPress={() => setFilteredSuggestions([])}
              >
                <Text style={styles.closeSuggestionButtonText}>X</Text>
              </TouchableOpacity>
            </View>
          )}
  
          {showChatBubble && (
            <View style={styles.chatBubble}>
              <Text>{chatBubbleContent}</Text>
              <TouchableOpacity onPress={clearMarkers}>
                <Text style={styles.closeButtonText}>X</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
  
      <div ref={mapRef} style={styles.map}></div>
      
      {isDropdownVisible && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={isDropdownVisible}
          onRequestClose={() => setIsDropdownVisible(false)}
        >
          <View style={styles.modalView}>
            <TouchableOpacity 
              style={styles.modalCloseButton} 
              onPress={() => setIsDropdownVisible(false)}
            >
              <Text style={styles.modalCloseButtonText}>X</Text>
            </TouchableOpacity>
            {suggestions.map((suggestion, index) => (
              <TouchableOpacity
                key={index}
                style={styles.modalItem}
                onPress={() => {
                  handlePickerSelect(suggestion);
                  setIsDropdownVisible(false);
                }}
              >
                <Text style={styles.modalText}>{suggestion}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </Modal>
      )}
    </View>
  );  
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  circleButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    width: 50,
    height: 50,
    backgroundColor: '#4A90E2', // Your button color
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 20, // Higher zIndex than popupWindow
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold', // Making the text bold
  },
  popupWindow: {
    position: 'absolute',
    top: 45,
    left: 40,
    width: 300, // Set a fixed width for the popup window
    height: 250,
    backgroundColor: 'white',
    paddingTop: 40,
    paddingHorizontal: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 10,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 5,
    marginHorizontal: 20, // Adjusted to align with popupWindow
    marginTop: 10,
    position: 'relative',
  },
  searchInput: {
    flex: 1, // Take up remaining space
    height: 40,
    padding: 10,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRightWidth: 0, // Prevent double border
    fontSize: 16,
  },
  chatBubble: {
    backgroundColor: '#f0f0f0',
    borderRadius: 20,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between', // Distribute space between text and button
    marginTop: 10,
    width: '50%', // Adjusted for dynamic sizing
    fontFamily: 'monospace',
    position: 'relative',
  },
  closeButton: {
    backgroundColor: 'red',
    borderRadius: 15,
    position: 'absolute',
    top: 5,
    right: 8, // Positioned on the far right
    width: 20,
    height: 20, // Adjust for a proportional look
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButtonText: {
    color: 'grey',
    fontWeight: 'bold',
    fontSize: 18,
  },
  pickerStyle: {
    height: 50,
    width: 25,
  },
  map: {
    height: '100%',
    width: '100%',
  },
  buttonImage: {
    width: 50, // Adjust the width as needed
    height: 50, // Adjust the height as needed
    resizeMode: 'contain', // This ensures your image scales nicely
    borderRadius: 25,  // Half of width/height to make it circular
    overflow: 'hidden', 
  },
  dropdownButton: {
    paddingRight: 10,
    borderColor: 'gray',
  },
  dropdownButtonText: {
    fontSize: 16,
  },
  modalView: {
    marginTop: 22,
    backgroundColor: 'white',
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
  },
  modalText: {
    fontSize: 18,
  },
  suggestionContainer: {
    position: 'absolute',
    top: 90, // Adjust this value as needed
    left: 40, // Align with the left side of searchContainer
    right: 20, // Align with the right side of searchContainer
    backgroundColor: 'white',
    zIndex: 20,
    maxHeight: 200,
    width: 220,
    overflow: 'auto',
    borderColor: 'gray',
    borderWidth: 1,
    borderTopWidth: 0,
  },
  suggestionItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
  },
  closeSuggestionButton: {
    position: 'absolute',
    right: 10, // Positioned on the far right
    top: 10, // Positioned at the top
    backgroundColor: 'lightgrey',
    borderRadius: 10,
    width: 20,
    height: 20, // Adjust for a proportional look
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 30, // Ensure it's above the suggestions
  },
  closeSuggestionButtonText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 12,
  },
  modalCloseButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  modalCloseButtonText: {
    fontSize: 20,
    color: 'black',
  },
  searchButton: {
    top: 100,
    paddingHorizontal: 8, // Reducing horizontal padding for a smaller width
    paddingVertical: 5, // Reducing vertical padding for a smaller height
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: '#4A90E2', // Professional-looking blue color
  },
  searchButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold', // Bold text to make it more clickable
  },
});

export default App;
